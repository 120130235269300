@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

:root {
  --text: #b7c9e0;
  --backgroundColor: #1b263b;
  --accent: #28979c;
}

/* Theme toggle  */

.theme-dark {
  --text: #ffffff;
  --secondaryText: #b7c9e0;
  --navText: #ffffff;

  --svgColor: invert(100%) sepia(0%) saturate(7500%) hue-rotate(124deg) brightness(101%) contrast(106%);

  --background: #1b263b;
  --light-background: #586f7c;
  --button-border: #b8dbd9;
  --darkBlock: block;
  --lightBlock: none;
}

.theme-light {
  --text: #000000;
  --secondaryText: #186f74;
  --navText: #219ba1;

  --svgColor: invert(0%) sepia(0%) saturate(9%) hue-rotate(252deg) brightness(101%) contrast(101%);

  --background: #dbe7e4;
  --light-background: #eddcd2;
  --button-border: #5e4b56;
  --darkBlock: none;
  --lightBlock: block;
}

nav {
  background-color: var(--dark-background);
  color: var(--light-text);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Chivo", "Inter", sans-serif;
}

body {
  overflow-x: hidden;
  background-color: var(--background);
  color: var(--text);
}

a {
  text-decoration: none;
  color: var(--text);
}

p {
  line-height: 1.6;
  color: var(--secondaryText);
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--heading);
}

h2 {
  margin: 25px 0;
  font-size: 2rem;
  color: var(--heading);
}

h3 {
  font-size: 1.6rem;
}

section {
  margin: 3rem 0;
}

.wrapper {
  max-width: 1280px;
  padding: 0 2.5rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

img,
video {
  max-width: 100%;
}

ul {
  list-style: none;
}

/* Button Styles from Foundation  */
.btn {
  appearance: none;
  background-color: #31c2b6;
  border: 2px solid transparent;
  border-radius: 5px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  outline: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.btn:hover {
  color: #fff;
  background-color: #2f2f2fc0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
  border: 2px solid white;
}

.btn:active {
  box-shadow: none;
  transform: translateY(0);
}

/* Navbar */

.menu-icon {
  display: none;
}

.close-icon {
  display: none;
}

.closed-nav-links {
  opacity: 0;
}

.nav-modal {
  display: none;
}

.nav-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 778px) {
  .wrapper {
    padding: 0 2rem;
  }

  /* Mobile Navbar   */
  .menu-icon,
  .close-icon {
    margin-right: 15px;
  }

  .no-menu {
    display: none;
  }

  .active-menu {
    display: block;
  }

  .nav-modal {
    display: flex;
    flex-direction: column;
    background-color: var(--backgroundColor);
    height: 100%;
    width: 100%;
    z-index: 1000;
    position: fixed;
    pointer-events: auto;
    right: -200vw;
    transition: all 0.5s ease-in;
    overflow-y: scroll;
    bottom: 0;
    opacity: 1;
  }

  .nav-link-container {
    display: none;
  }

  .active-nav-links {
    transform: translateX(-200%);
    transition: transform 0.5s ease-in;
  }

  .active-nav-links li,
  .closed-nav-links li {
    border-bottom: 1px solid rgba(180, 180, 180, 0.76);
    padding: 14px 0;
    opacity: 1;
  }

  body.active-navLinks {
    overflow: hidden;
  }
}

@media screen and (max-width: 481px) {
  .wrapper {
    padding: 0 1.5rem;
  }
}