.wrapper {
  padding: 2rem 0;
  margin: 0 auto;
  width: 100%;
  background-color: #202d46;
  font-size: 1.2rem;
  color: white;
}

.text {
  text-align: center;
}
