.container {
  margin: 12px 0;
}

.blogBox {
  display: flex;
  border: 2px solid var(--accent);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  transition: all 0.16s ease-in;
}

.imgDiv {
  width: 50%;
  max-width: 350px;
}

.textDiv {
  padding-left: 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.paragraph {
  margin: 10px 0;
}

.date {
  font-size: 0.9rem;
  color: rgb(168, 168, 168);
}

.heading {
  font-size: 1.5em;
  color: var(--heading);
}

.blogBox:hover {
  box-shadow: rgba(35, 143, 149, 0.082) 0px 38px 80px 0px;
  border-color: #30ccd4;
}

@media screen and (max-width: 778px) {
  .blogBox {
    flex-direction: column;
    align-items: center;
  }

  .imgDiv,
  .textDiv {
    max-width: 500px;

    width: 100%;
  }

  .textDiv {
    padding-left: 0;
  }

  .heading {
    margin: 10px 0;
  }
}

@media screen and (max-width: 481px) {
  .blogBox {
    padding: 1rem;
  }
}
